import { APICore } from "./apiCore";

const api = new APICore();

function getTitre(titreId: any) {
  const baseUrl = `/titres/` + titreId;
  return api.get(`${baseUrl}`, "");
}

function allTitre() {
  const baseUrl = "/titres";
  return api.get(`${baseUrl}`, "");
}

function createTitre(params: {
  fullName: string;
  mobile: string;
  numeroCompteClient?: string;
  status?: string;
  dateNaissance: string;
  lieuNaissance: string;
  nationalite: string;
  adresse: string;
  telephone?: string;
  fax?: string;
  email: string;
  idCard: string;
  sexe: string;
  degre: string;
  objectif: string;
  referenceBancaire: string;
  premierContact?: string;
  photo?: any;
  operations?: string[];
  mandatGestion?: string;
  ouvertureCompteTitre?: string;
  montant: number;
  interet: number;
  periode: number;
  userId: string;
}) {
  const baseUrl = "/titres";
  const response = api.createWithFile(baseUrl, params);
  return response;
}

function updateTitre(titreId: string, titreData: any) {
  const baseUrl = `/titres/${titreId}`;
  //Mise à jour des fichiers du titre
  if ((titreData.mandatGestion && titreData.mandatGestion.name) || (titreData.ouvertureCompteTitre && titreData.ouvertureCompteTitre.name)) {
    const baseUrl = `/upload`;
    titreData['_id'] = titreId
    return api.createWithFile(baseUrl, titreData);
  } else {
    return api.update(`${baseUrl}`, titreData);
  }
}

function deleteTitre(_id: any) {
  const baseUrl = `/titres/${_id}`;
  return api.delete(`${baseUrl}`);
}

//OPERATIONS
function getOperations() {
  const baseUrl = "/operations";
  return api.get(`${baseUrl}`, "");
}

function createOperation(params: {
  titre: string;
  quantite: number;
  titresNantis?: string;
  cmp: number;
  cours?: number;
  plusMoinsValue?: number;
  capitalAchat?: number;
  valuePorcentage?: number;
  dividende?: number;
  interetCouru?: number;
  value?: string;
  statut?: string;
  userId?: string;
  titreId?: string;
  typeOperation?: string;
}) {
  const baseUrl = "/titres/operation";
  const response = api.create(baseUrl, params);
  return response;
}

function updateOperation(operationId: string, operationDataData: any) {
  const baseUrl = `/operations/${operationId}`;
  //Mise à jour des fichiers du titre
  return api.update(`${baseUrl}`, operationDataData);
}

//MOUVEMENT
function createMouvement(params: {
  libelle: string;
  debit: number;
  credit: number;
  compteId: string;
  typeMouvement: string;
  userId: string;
}) {
  const baseUrl = "/titres/mouvement";
  const response = api.create(baseUrl, params);
  return response;
}

export { createTitre, updateTitre, deleteTitre, allTitre, getTitre, createOperation, updateOperation, createMouvement, getOperations };
