import { DealActionTypes } from "./constants";
import { Deal } from "models/Deal";

type State = {
  deals: Deal[];
  deal: Deal | {};
  loading: boolean;
  error: string | null;
  success: boolean;
  deleted: boolean;
};

const initialState: State = {
  deals: [],
  deal: {},
  loading: false,
  error: null,
  success: false,
  deleted: false,
};

const Deals = (state = initialState, action: any) => {
  switch (action.type) {
    case DealActionTypes.ALL_DEAL:
      return {
        ...state,
        loading: true,
      };
    case DealActionTypes.GET_DEAL:
      return {
        ...state,
        loading: true,
      };
    case DealActionTypes.CREATE_DEAL:
      return {
        ...state,
        loading: true,
        error: null,
        success: false,
      };
    case DealActionTypes.DELETE_DEAL:
      return {
        ...state,
        loading: true,
        error: null,
        success: false,
      };
    case DealActionTypes.API_RESPONSE_SUCCESS:
      if (action.payload.actionType === DealActionTypes.CREATE_DEAL) {
        return {
          ...state,
          loading: false,
          success: true,
        };
      } else if (action.payload.actionType === DealActionTypes.ALL_DEAL) {
        return {
          ...state,
          deals: action.payload.data,
          loading: false,
          success: false,
        };
      } else if (action.payload.actionType === DealActionTypes.GET_DEAL) {
        return {
          ...state,
          deal: action.payload.data,
          loading: false,
        };
      } else if (action.payload.actionType === DealActionTypes.DELETE_DEAL) {
        const newDeals = state.deals.filter(
          (deal) => deal._id !== action.payload.data._id
        );
        return {
          ...state,
          deals: newDeals,
          deleted: true,
        };
      }
      return state;
    case DealActionTypes.API_RESPONSE_ERROR:
      if (action.payload.actionType === DealActionTypes.CREATE_DEAL) {
        return {
          ...state,
          loading: false,
          error: action.payload.error,
          success: false,
        };
      } else if (action.payload.actionType === DealActionTypes.GET_DEAL) {
        return {
          ...state,
          loading: false,
          error: action.payload.error,
        };
      }
      return state;
    case DealActionTypes.RESET:
      return {
        ...state,
        loading: false,
        error: null,
        success: false,
        deleted: false,
      };
    default:
      return state;
  }
};

export default Deals;
