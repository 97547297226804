import { all } from "redux-saga/effects";
import authSaga from "./auth/saga";
import dealSaga from "./deal/saga";
import cdealSaga from "./cdeal/saga";
import userSaga from "./user/saga";
import clubSaga from "./club/saga";
import titreSaga from "./titre/saga";
import layoutSaga from "./layout/saga";

export default function* rootSaga() {
  yield all([authSaga(), dealSaga(), cdealSaga(), userSaga(), clubSaga(), titreSaga(), layoutSaga()]);
}
