export enum DealActionTypes {
  API_RESPONSE_SUCCESS = "@@deal/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@deal/API_RESPONSE_ERROR",

  ALL_DEAL = "@@deal/ALL_DEAL",
  GET_DEAL = "@@deal/GET_DEAL",
  CREATE_DEAL = "@@deal/CREATE_DEAL",
  UPDATE_DEAL = "@@deal/UPDATE_DEAL",
  DELETE_DEAL = "@@deal/DELETE_DEAL",

  RESET = "@@deal/RESET",
}
