export enum ClubActionTypes {
  API_RESPONSE_SUCCESS = "@@club/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@club/API_RESPONSE_ERROR",

  //CLUB
  ALL_CLUB = "@@club/ALL_CLUB",
  GET_CLUB = "@@club/GET_CLUB",
  CREATE_CLUB = "@@club/CREATE_CLUB",
  UPDATE_CLUB = "@@club/UPDATE_CLUB",
  DELETE_CLUB = "@@club/DELETE_CLUB",
  RESET_CLUB = "@@club/RESET_CLUB",
  CREATE_MOUVEMENT = "@@club/CREATE_MOUVEMENT",
  CREATE_COTISATION = "@@club/CREATE_COTISATION",
  UPDATE_COTISATION = "@@club/UPDATE_COTISATION",
  GET_COTISATIONS = "@@club/GET_COTISATIONS",
}
