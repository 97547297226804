import { APICore } from "./apiCore";

const api = new APICore();

function login(params: { email: string; password: string }) {
  const baseUrl = "/auth/login";
  return api.create(`${baseUrl}`, params);
}

function logout() {
  const baseUrl = "/auth/logout/";
  return api.create(`${baseUrl}`, {});
}

function signup(params: { username: string; email: string; password: string; fullName: string; phone: string }) {
  const baseUrl = "/auth/register/";
  return api.create(`${baseUrl}`, params);
}

function forgotPassword(params: { username: string }) {
  const baseUrl = "/auth/forget-password/";
  return api.create(`${baseUrl}`, params);
}

function resetPassword(params: any) {
  const baseUrl = "/auth/reset-password/";
  return api.create(`${baseUrl}`, params);
}

function forgotPasswordConfirm(params: { email: string }) {
  const baseUrl = "/auth/password/reset/confirm/";
  return api.create(`${baseUrl}`, params);
}

function confirmAccount(params: { token: string }) {
  const baseUrl = "/auth/confirm-account";
  return api.create(`${baseUrl}`, params);
}

export { login, logout, signup, forgotPassword, resetPassword, forgotPasswordConfirm, confirmAccount };
