import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

import {
  getDeal as getDealApi,
  getDeals as getDealsApi,
  createDeal as createDealApi,
  //updateDeal as updateDealApi,
  deleteDeal as deleteDealApi,
} from "helpers";

import { dealApiResponseSuccess, dealApiResponseError } from "./actions"; // Assurez-vous d'importer les actions appropriées depuis votre fichier d'actions.
import { DealActionTypes } from "./constants"; // Assurez-vous d'importer les constantes appropriées depuis votre fichier de constantes.

type DealData = {
  payload: {
    _id?: string;
    titre: string;
    description: string;
    caracteristique: string;
    //dateDebut: Date;
    //dateFin: Date;
    fichiers: any[];
  };
  type: string;
};

function* getDeals(): SagaIterator {
  try {
    const response = yield call(getDealsApi);
    if (response) {
      yield put(
        dealApiResponseSuccess(DealActionTypes.ALL_DEAL, response.data)
      );
    }
    // Assurez-vous d'appeler l'action appropriée pour gérer le succès de la création du deal.
  } catch (error: any) {
    yield put(dealApiResponseError(DealActionTypes.ALL_DEAL, error)); // Assurez-vous d'appeler l'action appropriée pour gérer les erreurs de la création du deal.
  }
}

function* createDeal({
  payload: {
    titre,
    description,
    caracteristique,
    //dateDebut,
    //dateFin,
    fichiers },
  type,
}: DealData): SagaIterator {
  try {
    const response = yield call(createDealApi, {
      titre,
      description,
      caracteristique,
      //dateDebut,
      //dateFin,
      fichiers,
    });
    if (response) {
      yield put(
        dealApiResponseSuccess(DealActionTypes.CREATE_DEAL, response.data)
      );
    }
    // Assurez-vous d'appeler l'action appropriée pour gérer le succès de la création du deal.
  } catch (error: any) {
    yield put(dealApiResponseError(DealActionTypes.CREATE_DEAL, error)); // Assurez-vous d'appeler l'action appropriée pour gérer les erreurs de la création du deal.
  }
}

function* deleteDeal({ payload: { _id }, type }: DealData): SagaIterator {
  try {
    const response = yield call(deleteDealApi, _id);

    if (response) {
      yield put(
        dealApiResponseSuccess(DealActionTypes.DELETE_DEAL, response.data)
      );
    }
  } catch (error: any) {
    yield put(dealApiResponseError(DealActionTypes.DELETE_DEAL, error));
  }
}

function* getDeal({ payload: { _id }, type }: DealData): SagaIterator {
  try {
    const response = yield call(getDealApi, _id);

    if (response) {
      yield put(
        dealApiResponseSuccess(DealActionTypes.GET_DEAL, response.data)
      );
    }
  } catch (error: any) {
    yield put(dealApiResponseError(DealActionTypes.GET_DEAL, error));
  }
}
/*
function* updateDeal({ payload: dealData }: DealData): SagaIterator {
  try {
    const response = yield call(updateDealApi, dealData); // Assurez-vous d'appeler la fonction d'assistance appropriée pour la mise à jour du deal.
    yield put(
      dealApiResponseSuccess(DealActionTypes.UPDATE_DEAL, response.data)
    ); // Assurez-vous d'appeler l'action appropriée pour gérer le succès de la mise à jour du deal.
  } catch (error: any) {
    yield put(dealApiResponseError(DealActionTypes.UPDATE_DEAL, error)); // Assurez-vous d'appeler l'action appropriée pour gérer les erreurs de la mise à jour du deal.
  }
}
*/
export function* watchCreateDeal() {
  yield takeEvery(DealActionTypes.CREATE_DEAL, createDeal);
}
export function* watchGetDeals() {
  yield takeEvery(DealActionTypes.ALL_DEAL, getDeals);
}
export function* watchGetDeal() {
  yield takeEvery(DealActionTypes.GET_DEAL, getDeal);
}

export function* watchDeleteDeal() {
  yield takeEvery(DealActionTypes.DELETE_DEAL, deleteDeal);
}

function* dealSaga() {
  yield all([fork(watchCreateDeal)]);
  yield all([fork(watchGetDeal)]);
  yield all([fork(watchGetDeals)]);
  yield all([fork(watchDeleteDeal)]);
}

export default dealSaga;
