import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

import {
  getClub as getClubApi,
  allClub as allClubApi,
  createClub as createClubApi,
  updateClub as updateClubApi,
  deleteClub as deleteClubApi,
  createClubMouvement as createClubMouvementApi,
  getCotisations as getCotisationsApi,
  createCotisation as createCotisationApi,
  updateCotisation as updateCotisationApi,
} from "helpers";

import { clubApiResponseSuccess, clubApiResponseError } from "./actions";
import { ClubActionTypes } from "./constants";

type ClubData = {
  payload: {
    _id?: string;
    compte?: string;
    fullName: string;
    mobile: string;
    numeroCompteClient?: string;
    club: string;
    status?: string;
    dateNaissance: string;
    lieuNaissance: string;
    nationalite: string;
    adresse: string;
    telephone?: string;
    fax?: string;
    email: string;
    idCard: string;
    sexe: string;
    degre: string;
    objectif: string;
    referenceBancaire: string;
    premierContact?: string;
    userId: string;
    ficheAdhesion: string;
    ouvertureCompteClub: string;
    photo: any;
    cotisation?: any;
  };
  type: string;
};

type CotisationData = {
  payload: {
    _id?: string;
    titre: string;
    typeClub?: string;
    periode?: string;
    rendement?: string;
    moisEntre?: string;
    moisSortie?: string;
    moisCotisationRestant?: string;
    retour?: string;
    valeur?: string;
  };
  type: string;
};

type MouvementData = {
  payload: {
    _id?: string;
    libelle: string;
    debit: number;
    credit: number;
    compteId: string;
    typeMouvement: string;
    userId: string;
  };
  type: string;
};

function* allClub(): SagaIterator {
  try {
    const response = yield call(allClubApi);
    if (response) {
      yield put(
        clubApiResponseSuccess(ClubActionTypes.ALL_CLUB, response.data)
      );
    }
  } catch (error: any) {
    yield put(clubApiResponseError(ClubActionTypes.ALL_CLUB, error)); // Assurez-vous d'appeler l'action appropriée pour gérer les erreurs de la création du deal.
  }
}

function* createClub({
  payload: {
    fullName,
    mobile,
    numeroCompteClient,
    club,
    status,
    dateNaissance,
    lieuNaissance,
    nationalite,
    adresse,
    telephone,
    fax,
    email,
    idCard,
    sexe,
    degre,
    objectif,
    referenceBancaire,
    premierContact,
    userId,
    ficheAdhesion,
    ouvertureCompteClub,
    photo,
    cotisation,
  },
  type,
}: ClubData): SagaIterator {
  try {
    const response = yield call(createClubApi, {
      fullName,
      mobile,
      numeroCompteClient,
      club,
      status,
      dateNaissance,
      lieuNaissance,
      nationalite,
      adresse,
      telephone,
      fax,
      email,
      idCard,
      sexe,
      degre,
      objectif,
      referenceBancaire,
      premierContact,
      userId,
      ficheAdhesion,
      ouvertureCompteClub,
      photo,
      cotisation,
    });
    if (response) {
      if (photo) {
        yield call(updateClubApi, response.data._id, photo);
      }
      yield put(
        clubApiResponseSuccess(ClubActionTypes.CREATE_CLUB, response.data)
      );
    }
  } catch (error: any) {
    yield put(clubApiResponseError(ClubActionTypes.CREATE_CLUB, error));
  }
}

function* deleteClub({ payload: { _id }, type }: ClubData): SagaIterator {
  try {
    const response = yield call(deleteClubApi, _id);

    if (response) {
      yield put(
        clubApiResponseSuccess(ClubActionTypes.DELETE_CLUB, response.data)
      );
    }
  } catch (error: any) {
    yield put(clubApiResponseError(ClubActionTypes.DELETE_CLUB, error));
  }
}

function* getClub({ payload: { _id }, type }: ClubData): SagaIterator {
  try {
    const response = yield call(getClubApi, _id);

    if (response) {
      yield put(
        clubApiResponseSuccess(ClubActionTypes.GET_CLUB, response.data)
      );
    }
  } catch (error: any) {
    yield put(clubApiResponseError(ClubActionTypes.GET_CLUB, error));
  }
}

function* updateClub({ payload: { clubId, updatedData } }: any): SagaIterator {
  try {
    const response = yield call(updateClubApi, clubId, updatedData);
    yield put(
      clubApiResponseSuccess(ClubActionTypes.UPDATE_CLUB, response.data)
    );
  } catch (error: any) {
    yield put(clubApiResponseError(ClubActionTypes.UPDATE_CLUB, error));
  }
}

function* getCotisations(): SagaIterator {
  try {
    const response = yield call(getCotisationsApi);
    if (response) {
      yield put(
        clubApiResponseSuccess(ClubActionTypes.GET_COTISATIONS, response.data)
      );
    }
  } catch (error: any) {
    yield put(clubApiResponseError(ClubActionTypes.GET_COTISATIONS, error));
  }
}

function* createCotisation({
  payload: {
    titre,
    typeClub,
    periode,
    rendement,
    moisEntre,
    moisSortie,
    moisCotisationRestant,
    retour,
    valeur,
  },
  type,
}: CotisationData): SagaIterator {
  try {
    const response = yield call(createCotisationApi, {
      titre,
      typeClub,
      periode,
      rendement,
      moisEntre,
      moisSortie,
      moisCotisationRestant,
      retour,
      valeur,
    });
    if (response) {
      yield put(
        clubApiResponseSuccess(ClubActionTypes.CREATE_COTISATION, response.data)
      );
    }
  } catch (error: any) {
    yield put(clubApiResponseError(ClubActionTypes.CREATE_COTISATION, error));
  }
}

function* updateCotisation({ payload: { cotisationId, updatedData } }: any): SagaIterator {
  try {
    const response = yield call(updateCotisationApi, cotisationId, updatedData);
    yield put(
      clubApiResponseSuccess(ClubActionTypes.UPDATE_COTISATION, response.data)
    );
  } catch (error: any) {
    yield put(clubApiResponseError(ClubActionTypes.UPDATE_COTISATION, error));
  }
}


function* createClubMouvement({
  payload: {
    libelle,
    debit,
    credit,
    compteId,
    typeMouvement,
    userId
  },
  type,
}: MouvementData): SagaIterator {
  try {
    const response = yield call(createClubMouvementApi, {
      libelle,
      debit,
      credit,
      compteId,
      typeMouvement,
      userId
    });
    if (response) {
      yield put(
        clubApiResponseSuccess(ClubActionTypes.CREATE_MOUVEMENT, response.data)
      );
    }
  } catch (error: any) {
    yield put(clubApiResponseError(ClubActionTypes.CREATE_MOUVEMENT, error));
  }
}

export function* watchCreateClub() {
  yield takeEvery(ClubActionTypes.CREATE_CLUB, createClub);
}
export function* watchCreateCotisation() {
  yield takeEvery(ClubActionTypes.CREATE_COTISATION, createCotisation);
}
export function* watchCreateClubMouvement() {
  yield takeEvery(ClubActionTypes.CREATE_MOUVEMENT, createClubMouvement);
}
export function* watchAllClub() {
  yield takeEvery(ClubActionTypes.ALL_CLUB, allClub);
}
export function* watchGetCotisations() {
  yield takeEvery(ClubActionTypes.GET_COTISATIONS, getCotisations);
}
export function* watchGetClub() {
  yield takeEvery(ClubActionTypes.GET_CLUB, getClub);
}
export function* watchUpdateClub() {
  yield takeEvery(ClubActionTypes.UPDATE_CLUB, updateClub);
}
export function* watchUpdateCotisation() {
  yield takeEvery(ClubActionTypes.UPDATE_COTISATION, updateCotisation);
}

export function* watchDeleteClub() {
  yield takeEvery(ClubActionTypes.DELETE_CLUB, deleteClub);
}

function* userSaga() {
  yield all([fork(watchAllClub)]);
  yield all([fork(watchGetClub)]);
  yield all([fork(watchUpdateClub)]);
  yield all([fork(watchCreateClub)]);
  yield all([fork(watchDeleteClub)]);
  yield all([fork(watchGetCotisations)]);
  yield all([fork(watchCreateCotisation)]);
  yield all([fork(watchUpdateCotisation)]);
  yield all([fork(watchCreateClubMouvement)]);
}

export default userSaga;
