import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

import {
  getTitre as getTitreApi,
  allTitre as allTitreApi,
  createTitre as createTitreApi,
  updateTitre as updateTitreApi,
  deleteTitre as deleteTitreApi,
  createOperation as createOperationApi,
  getOperations as getOperationsApi,
  updateOperation as updateOperationApi,
  createMouvement as createMouvementApi,
} from "helpers";

import { titreApiResponseSuccess, titreApiResponseError } from "./actions";
import { TitreActionTypes } from "./constants";

type TitreData = {
  payload: {
    _id?: string;
    fullName: string;
    mobile: string;
    dateNaissance: string;
    lieuNaissance: string;
    nationalite: string;
    adresse: string;
    telephone?: string;
    fax?: string;
    email: string;
    idCard: string;
    sexe: string;
    degre: string;
    objectif: string;
    referenceBancaire: string;
    premierContact?: string;
    userId: string;
    mandatGestion: string;
    ouvertureCompteTitre: string;
    photo: any;
    montant: number;
    interet: number;
    periode: number;
  };
  type: string;
};

type OperationData = {
  payload: {
    _id?: string;
    titre: string;
    quantite: number;
    titresNantis?: string;
    cmp: number;
    cours?: number;
    plusMoinsValue?: number;
    capitalAchat?: number;
    valuePorcentage?: number;
    dividende?: number;
    interetCouru?: number;
    value?: string;
    statut?: string;
    userId?: string
    titreId?: string
    typeOperation: string
  };
  type: string;
};

type MouvementData = {
  payload: {
    _id?: string;
    libelle: string;
    debit: number;
    credit: number;
    compteId: string;
    typeMouvement: string;
    userId: string;
  };
  type: string;
};

function* allTitre(): SagaIterator {
  try {
    const response = yield call(allTitreApi);
    if (response) {
      yield put(
        titreApiResponseSuccess(TitreActionTypes.ALL_TITRE, response.data)
      );
    }
  } catch (error: any) {
    yield put(titreApiResponseError(TitreActionTypes.ALL_TITRE, error)); // Assurez-vous d'appeler l'action appropriée pour gérer les erreurs de la création du deal.
  }
}

function* createTitre({
  payload: {
    fullName,
    mobile,
    dateNaissance,
    lieuNaissance,
    nationalite,
    adresse,
    telephone,
    fax,
    email,
    idCard,
    sexe,
    degre,
    objectif,
    referenceBancaire,
    premierContact,
    userId,
    mandatGestion,
    ouvertureCompteTitre,
    photo,
    montant,
    interet,
    periode,
  },
  type,
}: TitreData): SagaIterator {
  try {
    const response = yield call(createTitreApi, {
      fullName,
      mobile,
      dateNaissance,
      lieuNaissance,
      nationalite,
      adresse,
      telephone,
      fax,
      email,
      idCard,
      sexe,
      degre,
      objectif,
      referenceBancaire,
      premierContact,
      userId,
      mandatGestion,
      ouvertureCompteTitre,
      photo,
      montant,
      interet,
      periode,
    });
    if (response) {
      yield put(
        titreApiResponseSuccess(TitreActionTypes.CREATE_TITRE, response.data)
      );
    }
  } catch (error: any) {
    yield put(titreApiResponseError(TitreActionTypes.CREATE_TITRE, error));
  }
}

function* deleteTitre({ payload: { _id }, type }: TitreData): SagaIterator {
  try {
    const response = yield call(deleteTitreApi, _id);

    if (response) {
      yield put(
        titreApiResponseSuccess(TitreActionTypes.DELETE_TITRE, response.data)
      );
    }
  } catch (error: any) {
    yield put(titreApiResponseError(TitreActionTypes.DELETE_TITRE, error));
  }
}

function* getTitre({ payload: { _id }, type }: TitreData): SagaIterator {
  try {
    const response = yield call(getTitreApi, _id);

    if (response) {
      yield put(
        titreApiResponseSuccess(TitreActionTypes.GET_TITRE, response.data)
      );
    }
  } catch (error: any) {
    yield put(titreApiResponseError(TitreActionTypes.GET_TITRE, error));
  }
}

function* updateTitre({ payload: { titreId, updatedData } }: any): SagaIterator {
  try {
    const response = yield call(updateTitreApi, titreId, updatedData);
    yield put(
      titreApiResponseSuccess(TitreActionTypes.UPDATE_TITRE, response.data)
    );
  } catch (error: any) {
    yield put(titreApiResponseError(TitreActionTypes.UPDATE_TITRE, error));
  }
}

function* createOperation({
  payload: {
    titre,
    quantite,
    titresNantis,
    cmp,
    cours,
    plusMoinsValue,
    capitalAchat,
    valuePorcentage,
    dividende,
    interetCouru,
    value,
    statut,
    userId,
    titreId,
    typeOperation
  },
  type,
}: OperationData): SagaIterator {
  try {
    const response = yield call(createOperationApi, {
      titre,
      quantite,
      titresNantis,
      cmp,
      cours,
      plusMoinsValue,
      capitalAchat,
      valuePorcentage,
      dividende,
      interetCouru,
      value,
      statut,
      userId,
      titreId,
      typeOperation
    });
    if (response) {
      yield put(
        titreApiResponseSuccess(TitreActionTypes.CREATE_OPERATION, response.data)
      );
    }
  } catch (error: any) {
    yield put(titreApiResponseError(TitreActionTypes.CREATE_OPERATION, error));
  }
}

function* updateOperation({ payload: { operationId, updatedData } }: any): SagaIterator {
  try {
    const response = yield call(updateOperationApi, operationId, updatedData);
    yield put(
      titreApiResponseSuccess(TitreActionTypes.UPDATE_OPERATION, response.data)
    );
  } catch (error: any) {
    yield put(titreApiResponseError(TitreActionTypes.UPDATE_OPERATION, error));
  }
}

function* getOperations(): SagaIterator {
  try {
    const response = yield call(getOperationsApi);
    if (response) {
      yield put(
        titreApiResponseSuccess(TitreActionTypes.GET_OPERATIONS, response.data)
      );
    }
  } catch (error: any) {
    yield put(titreApiResponseError(TitreActionTypes.GET_OPERATIONS, error)); // Assurez-vous d'appeler l'action appropriée pour gérer les erreurs de la création du deal.
  }
}

function* createMouvement({
  payload: {
    libelle,
    debit,
    credit,
    compteId,
    typeMouvement,
    userId
  },
  type,
}: MouvementData): SagaIterator {
  try {
    const response = yield call(createMouvementApi, {
      libelle,
      debit,
      credit,
      compteId,
      typeMouvement,
      userId
    });
    if (response) {
      yield put(
        titreApiResponseSuccess(TitreActionTypes.CREATE_MOUVEMENT, response.data)
      );
    }
  } catch (error: any) {
    yield put(titreApiResponseError(TitreActionTypes.CREATE_MOUVEMENT, error));
  }
}

export function* watchCreateTitre() {
  yield takeEvery(TitreActionTypes.CREATE_TITRE, createTitre);
}
export function* watchAllTitre() {
  yield takeEvery(TitreActionTypes.ALL_TITRE, allTitre);
}
export function* watchGetOperations() {
  yield takeEvery(TitreActionTypes.GET_OPERATIONS, getOperations);
}
export function* watchGetTitre() {
  yield takeEvery(TitreActionTypes.GET_TITRE, getTitre);
}
export function* watchUpdateTitre() {
  yield takeEvery(TitreActionTypes.UPDATE_TITRE, updateTitre);
}
export function* watchCreateOperation() {
  yield takeEvery(TitreActionTypes.CREATE_OPERATION, createOperation);
}
export function* watchUpdateOperation() {
  yield takeEvery(TitreActionTypes.UPDATE_OPERATION, updateOperation);
}
export function* watchCreateMouvement() {
  yield takeEvery(TitreActionTypes.CREATE_MOUVEMENT, createMouvement);
}
export function* watchDeleteTitre() {
  yield takeEvery(TitreActionTypes.DELETE_TITRE, deleteTitre);
}

function* userSaga() {
  yield all([fork(watchAllTitre)]);
  yield all([fork(watchGetTitre)]);
  yield all([fork(watchUpdateTitre)]);
  yield all([fork(watchCreateTitre)]);
  yield all([fork(watchDeleteTitre)]);
  yield all([fork(watchGetOperations)]);
  yield all([fork(watchCreateOperation)]);
  yield all([fork(watchUpdateOperation)]);
  yield all([fork(watchCreateMouvement)]);
}

export default userSaga;
