import { TitreActionTypes } from "./constants";
import { Operation, Titre } from "models/Titre";

type State = {
  titres: Titre[];
  titre: Titre | {};
  operation: Operation | {};
  operations: Operation[];
  loading: boolean;
  error: string | null;
  success: boolean;
  deleted: boolean;
  added: boolean;
};

const initialState: State = {
  titres: [],
  titre: {},
  operation: {},
  operations: [],
  loading: false,
  error: null,
  success: false,
  deleted: false,
  added: false,
};

const Titres = (state = initialState, action: any) => {
  switch (action.type) {
    case TitreActionTypes.ALL_TITRE:
      return {
        ...state,
        loading: true,
      };
    case TitreActionTypes.GET_TITRE:
      return {
        ...state,
        loading: true,
      };
    case TitreActionTypes.UPDATE_TITRE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case TitreActionTypes.CREATE_TITRE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case TitreActionTypes.GET_OPERATIONS:
      return {
        ...state,
        loading: true,
      };
    case TitreActionTypes.UPDATE_OPERATION:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case TitreActionTypes.CREATE_OPERATION:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case TitreActionTypes.CREATE_MOUVEMENT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case TitreActionTypes.DELETE_TITRE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case TitreActionTypes.API_RESPONSE_SUCCESS:
      if (action.payload.actionType === TitreActionTypes.CREATE_TITRE) {
        return {
          ...state,
          loading: false,
          added: true,
        };
      } else if (action.payload.actionType === TitreActionTypes.ALL_TITRE) {
        return {
          ...state,
          titres: action.payload.data,
          loading: false,
          success: false,
        };
      } else if (action.payload.actionType === TitreActionTypes.GET_TITRE) {
        return {
          ...state,
          titre: action.payload.data,
          loading: false,
        };
      } else if (action.payload.actionType === TitreActionTypes.UPDATE_TITRE) {
        return {
          ...state,
          titre: action.payload.data,
          loading: false,
          success: true,
        };
      } else if (action.payload.actionType === TitreActionTypes.GET_OPERATIONS) {
        return {
          ...state,
          operations: action.payload.data,
          loading: false,
          success: false,
        };
      } else if (action.payload.actionType === TitreActionTypes.CREATE_OPERATION) {
        return {
          ...state,
          loading: false,
          added: true,
        };
      } else if (action.payload.actionType === TitreActionTypes.CREATE_MOUVEMENT) {
        return {
          ...state,
          loading: false,
          added: true,
        };
      } else if (action.payload.actionType === TitreActionTypes.UPDATE_OPERATION) {
        return {
          ...state,
          operation: action.payload.data,
          loading: false,
          success: true,
        };
      } else if (action.payload.actionType === TitreActionTypes.DELETE_TITRE) {
        const newTitres = state.titres.filter(
          (titre) => titre._id !== action.payload.data._id
        );
        return {
          ...state,
          titres: newTitres,
          loading: false,
          deleted: true,
        };
      }
      return state;
    case TitreActionTypes.API_RESPONSE_ERROR:
      if (action.payload.actionType === TitreActionTypes.CREATE_TITRE) {
        return {
          ...state,
          loading: false,
          error: action.payload.error,
          added: false,
        };
      } else if (action.payload.actionType === TitreActionTypes.GET_TITRE) {
        return {
          ...state,
          loading: false,
          error: action.payload.error,
        };
      } else if (action.payload.actionType === TitreActionTypes.UPDATE_TITRE) {
        return {
          ...state,
          loading: false,
          error: action.payload.error,
        };
      }
      else if (action.payload.actionType === TitreActionTypes.GET_OPERATIONS) {
        return {
          ...state,
          loading: false,
          error: action.payload.error,
        };
      } else if (action.payload.actionType === TitreActionTypes.CREATE_OPERATION) {
        return {
          ...state,
          loading: false,
          error: action.payload.error,
        };
      } else if (action.payload.actionType === TitreActionTypes.UPDATE_OPERATION) {
        return {
          ...state,
          loading: false,
          error: action.payload.error,
        };
      } else if (action.payload.actionType === TitreActionTypes.CREATE_MOUVEMENT) {
        return {
          ...state,
          loading: false,
          error: action.payload.error,
        };
      }
      return state;
    case TitreActionTypes.RESET_TITRE:
      return {
        ...state,
        loading: false,
        error: null,
        success: false,
        deleted: false,
        added: false,
      };
    default:
      return state;
  }
};

export default Titres;
