import { APICore } from 'helpers/api/apiCore';
import { Navigate } from 'react-router-dom';

const api = new APICore();
const Root = () => {
  if (api.isUserAuthenticated()) {
    return <Navigate to={`/dashboard/deals`} />;
  } else {
    return <Navigate to={`/account/login`} />;
  }
};

export default Root;
