import { APICore } from "./apiCore";

const api = new APICore();

function createUserMail(params: {
  data: any
}) {
  const baseUrl = "/mail";
  return api.create(`${baseUrl}`, params);
}

export { createUserMail };
