export enum UserActionTypes {
  API_RESPONSE_SUCCESS = "@@user/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@user/API_RESPONSE_ERROR",

  ALL_USER = "@@user/ALL_USER",
  GET_USER = "@@user/GET_USER",
  CREATE_USER = "@@user/CREATE_USER",
  UPDATE_USER = "@@user/UPDATE_USER",
  DELETE_USER = "@@user/DELETE_USER",

  RESET = "@@user/RESET",
}
