import { CDealActionTypes } from "./constants";

export type cDealActionType = {
  type:
  | CDealActionTypes.API_RESPONSE_SUCCESS
  | CDealActionTypes.API_RESPONSE_ERROR
  | CDealActionTypes.ALL_CDEAL
  | CDealActionTypes.GET_CDEAL
  | CDealActionTypes.CREATE_CDEAL
  | CDealActionTypes.UPDATE_CDEAL
  | CDealActionTypes.GET_OPERATIONS
  | CDealActionTypes.CREATE_OPERATION
  | CDealActionTypes.UPDATE_OPERATION
  | CDealActionTypes.DELETE_CDEAL
  | CDealActionTypes.RESET_CDEAL
  | CDealActionTypes.CREATE_CMOUVEMENT
  payload: {} | string;
};

export const cdealApiResponseSuccess = (
  actionType: string,
  data: any
): cDealActionType => ({
  type: CDealActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const cdealApiResponseError = (
  actionType: string,
  error: string
): cDealActionType => ({
  type: CDealActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

//CDEAL
export const allcDeal = (): cDealActionType => ({
  type: CDealActionTypes.ALL_CDEAL,
  payload: {},
});

export const getcDeal = (_id: any): cDealActionType => ({
  type: CDealActionTypes.GET_CDEAL,
  payload: { _id },
});

export const createcDeal = (dealData: any): cDealActionType => ({
  type: CDealActionTypes.CREATE_CDEAL,
  payload: dealData,
});

export const updatecDeal = (
  dealId: string,
  updatedData: any
): cDealActionType => ({
  type: CDealActionTypes.UPDATE_CDEAL,
  payload: { dealId, updatedData },
});

export const deletecDeal = (_id: any): cDealActionType => ({
  type: CDealActionTypes.DELETE_CDEAL,
  payload: { _id },
});

export const resetcDeal = (): cDealActionType => ({
  type: CDealActionTypes.RESET_CDEAL,
  payload: {},
});


//MOUVEMENT
export const createcMouvement = (mouvementData: any): cDealActionType => ({
  type: CDealActionTypes.CREATE_CMOUVEMENT,
  payload: mouvementData,
});
