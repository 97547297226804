export enum TitreActionTypes {
  API_RESPONSE_SUCCESS = "@@titre/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@titre/API_RESPONSE_ERROR",

  //TITRE
  ALL_TITRE = "@@titre/ALL_TITRE",
  GET_TITRE = "@@titre/GET_TITRE",
  CREATE_TITRE = "@@titre/CREATE_TITRE",
  GET_OPERATIONS = "@@titre/GET_OPERATIONS",
  CREATE_OPERATION = "@@titre/CREATE_OPERATION",
  UPDATE_OPERATION = "@@titre/UPDATE_OPERATION",
  UPDATE_TITRE = "@@titre/UPDATE_TITRE",
  DELETE_TITRE = "@@titre/DELETE_TITRE",
  RESET_TITRE = "@@titre/RESET_TITRE",
  CREATE_MOUVEMENT = "@@titre/CREATE_MOUVEMENT",
}
