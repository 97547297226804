import { DealActionTypes } from "./constants";

export type DealActionType = {
  type:
    | DealActionTypes.API_RESPONSE_SUCCESS
    | DealActionTypes.API_RESPONSE_ERROR
    | DealActionTypes.ALL_DEAL
    | DealActionTypes.GET_DEAL
    | DealActionTypes.CREATE_DEAL
    | DealActionTypes.UPDATE_DEAL
    | DealActionTypes.DELETE_DEAL
    | DealActionTypes.RESET;
  payload: {} | string;
};

export const dealApiResponseSuccess = (
  actionType: string,
  data: any
): DealActionType => ({
  type: DealActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const dealApiResponseError = (
  actionType: string,
  error: string
): DealActionType => ({
  type: DealActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const allDeal = (): DealActionType => ({
  type: DealActionTypes.ALL_DEAL,
  payload: {},
});

export const createDeal = (dealData: any): DealActionType => ({
  type: DealActionTypes.CREATE_DEAL,
  payload: dealData,
});

export const getDeal = (_id: any): DealActionType => ({
  type: DealActionTypes.GET_DEAL,
  payload: { _id },
});

export const updateDeal = (
  dealId: string,
  updatedData: any
): DealActionType => ({
  type: DealActionTypes.UPDATE_DEAL,
  payload: { dealId, updatedData },
});

export const deleteDeal = (_id: any): DealActionType => ({
  type: DealActionTypes.DELETE_DEAL,
  payload: { _id },
});

export const resetDeal = (): DealActionType => ({
  type: DealActionTypes.RESET,
  payload: {},
});
