import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";
import { APICore, setAuthorization } from "helpers/api/apiCore";
import {
  login as loginApi,
  //logout as logoutApi,
  signup as signupApi,
  forgotPassword as forgotPasswordApi,
  resetPassword as resetPasswordApi,
} from "helpers";
import { authApiResponseSuccess, authApiResponseError } from "./actions";
import { AuthActionTypes } from "./constants";

type UserData = {
  payload: {
    username: string;
    password: string;
    email: string;
    fullName: string;
    phone: string;
    token?: string;
  };
  type: string;
};

const api = new APICore();

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({
  payload: { email, password },
  type,
}: UserData): SagaIterator {
  try {
    const response = yield call(loginApi, { email, password });
    if (response) {
      const user = response.data.user;
      api.setLoggedInUser(user);
      setAuthorization(user.token);
      yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, user));
    }
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, error));
    api.setLoggedInUser(null);
    setAuthorization(null);
  }
}

/**
 * Logout the user
 */
function* logout(): SagaIterator {
  try {
    api.setLoggedInUser(null);
    setAuthorization(null);
    yield put(authApiResponseSuccess(AuthActionTypes.LOGOUT_USER, {}));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.LOGOUT_USER, error));
  }
}

function* signup({
  payload: { username, email, password, fullName, phone },
}: UserData): SagaIterator {
  try {
    const response = yield call(signupApi, { username, email, password, fullName, phone });
    const user = response.data;
    yield put(authApiResponseSuccess(AuthActionTypes.SIGNUP_USER, user));
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.SIGNUP_USER, error));
    api.setLoggedInUser(null);
    setAuthorization(null);
  }
}

function* forgotPassword({ payload: { username } }: UserData): SagaIterator {
  try {
    const response = yield call(forgotPasswordApi, { username });
    yield put(
      authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD, response.data)
    );
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD, error));
  }
}

function* resetPassword({ payload: { email, token, password } }: UserData): SagaIterator {
  try {
    const response = yield call(resetPasswordApi, { email, token, password });
    yield put(
      authApiResponseSuccess(AuthActionTypes.RESET_PASSWORD, response.data)
    );
  } catch (error: any) {
    yield put(authApiResponseError(AuthActionTypes.RESET_PASSWORD, error));
  }
}


export function* watchLoginUser() {
  yield takeEvery(AuthActionTypes.LOGIN_USER, login);
}

export function* watchLogout() {
  yield takeEvery(AuthActionTypes.LOGOUT_USER, logout);
}

export function* watchSignup() {
  yield takeEvery(AuthActionTypes.SIGNUP_USER, signup);
}

export function* watchForgotPassword() {
  yield takeEvery(AuthActionTypes.FORGOT_PASSWORD, forgotPassword);
}

export function* watchResetPassword() {
  yield takeEvery(AuthActionTypes.RESET_PASSWORD, resetPassword);
}

function* authSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogout),
    fork(watchSignup),
    fork(watchForgotPassword),
    fork(watchResetPassword),
  ]);
}

export default authSaga;
