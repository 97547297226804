import { APICore } from "./apiCore"; // Assurez-vous d'importer la classe APICore appropriée.

const api = new APICore(); // Initialisez l'instance APICore appropriée.

function getDeal(dealId: any) {
  const baseUrl = `/deal/` + dealId; // Assurez-vous d'ajuster l'URL de base pour la création de deals en fonction de votre API.
  return api.get(`${baseUrl}`, ""); // Assurez-vous d'appeler la fonction appropriée pour la création d'un deal.
}

function getDeals() {
  const baseUrl = "/deal"; // Assurez-vous d'ajuster l'URL de base pour la récupération de la liste des deals en fonction de votre API.
  return api.get(`${baseUrl}`, ""); // Assurez-vous d'appeler la fonction appropriée pour la récupération de la liste des deals.
}

function createDeal(params: {
  titre: string;
  description: string;
  caracteristique?: string;
  //dateDebut: Date;
  //dateFin: Date;
  fichiers?: any[];
}) {
  const baseUrl = "/deal"; // Assurez-vous d'ajuster l'URL de base pour la création de deals en fonction de votre API.
  return api.createWithFile(`${baseUrl}`, params); // Assurez-vous d'appeler la fonction appropriée pour la création d'un deal.
}

function updateDeal(dealId: string, dealData: any) {
  const baseUrl = `/deal/${dealId}`; // Assurez-vous d'ajuster l'URL de base pour la mise à jour d'un deal en fonction de votre API.
  return api.update(`${baseUrl}`, dealData); // Assurez-vous d'appeler la fonction appropriée pour la mise à jour d'un deal.
}

function deleteDeal(_id: any) {
  const baseUrl = `/deal/${_id}`;
  return api.delete(`${baseUrl}`);
}

export { createDeal, updateDeal, deleteDeal, getDeals, getDeal };
