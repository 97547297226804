import { CDealActionTypes } from "./constants";
import { CompteDeal } from "models/Deal";

type State = {
  cdeals: CompteDeal[];
  cdeal: CompteDeal | {};
  loading: boolean;
  error: string | null;
  success: boolean;
  deleted: boolean;
  added: boolean;
};

const initialState: State = {
  cdeals: [],
  cdeal: {},
  loading: false,
  error: null,
  success: false,
  deleted: false,
  added: false,
};

const cDeals = (state = initialState, action: any) => {
  switch (action.type) {
    case CDealActionTypes.ALL_CDEAL:
      return {
        ...state,
        loading: true,
      };
    case CDealActionTypes.GET_CDEAL:
      return {
        ...state,
        loading: true,
      };
    case CDealActionTypes.UPDATE_CDEAL:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CDealActionTypes.CREATE_CDEAL:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CDealActionTypes.GET_OPERATIONS:
      return {
        ...state,
        loading: true,
      };
    case CDealActionTypes.UPDATE_OPERATION:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CDealActionTypes.CREATE_OPERATION:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CDealActionTypes.CREATE_CMOUVEMENT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CDealActionTypes.DELETE_CDEAL:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CDealActionTypes.API_RESPONSE_SUCCESS:
      if (action.payload.actionType === CDealActionTypes.CREATE_CDEAL) {
        return {
          ...state,
          loading: false,
          added: true,
        };
      } else if (action.payload.actionType === CDealActionTypes.ALL_CDEAL) {
        return {
          ...state,
          cdeals: action.payload.data,
          loading: false,
          success: false,
        };
      } else if (action.payload.actionType === CDealActionTypes.GET_CDEAL) {
        return {
          ...state,
          cdeal: action.payload.data,
          loading: false,
        };
      } else if (action.payload.actionType === CDealActionTypes.UPDATE_CDEAL) {
        return {
          ...state,
          cdeal: action.payload.data,
          loading: false,
          success: true,
        };
      } else if (action.payload.actionType === CDealActionTypes.CREATE_CMOUVEMENT) {
        return {
          ...state,
          loading: false,
          added: true,
        };
      } else if (action.payload.actionType === CDealActionTypes.DELETE_CDEAL) {
        const newDeals = state.cdeals.filter(
          (cdeal) => cdeal._id !== action.payload.data._id
        );
        return {
          ...state,
          cdeals: newDeals,
          loading: false,
          deleted: true,
        };
      }
      return state;
    case CDealActionTypes.API_RESPONSE_ERROR:
      if (action.payload.actionType === CDealActionTypes.CREATE_CDEAL) {
        return {
          ...state,
          loading: false,
          error: action.payload.error,
          added: false,
        };
      } else if (action.payload.actionType === CDealActionTypes.GET_CDEAL) {
        return {
          ...state,
          loading: false,
          error: action.payload.error,
        };
      } else if (action.payload.actionType === CDealActionTypes.UPDATE_CDEAL) {
        return {
          ...state,
          loading: false,
          error: action.payload.error,
        };
      }
      else if (action.payload.actionType === CDealActionTypes.GET_OPERATIONS) {
        return {
          ...state,
          loading: false,
          error: action.payload.error,
        };
      } else if (action.payload.actionType === CDealActionTypes.CREATE_OPERATION) {
        return {
          ...state,
          loading: false,
          error: action.payload.error,
        };
      } else if (action.payload.actionType === CDealActionTypes.UPDATE_OPERATION) {
        return {
          ...state,
          loading: false,
          error: action.payload.error,
        };
      } else if (action.payload.actionType === CDealActionTypes.CREATE_CMOUVEMENT) {
        return {
          ...state,
          loading: false,
          error: action.payload.error,
        };
      }
      return state;
    case CDealActionTypes.RESET_CDEAL:
      return {
        ...state,
        loading: false,
        error: null,
        success: false,
        deleted: false,
        added: false,
      };
    default:
      return state;
  }
};

export default cDeals;
