import { APICore } from "./apiCore";

const api = new APICore();

function getClub(clubId: any) {
  const baseUrl = `/clubs/` + clubId;
  return api.get(`${baseUrl}`, "");
}

function allClub() {
  const baseUrl = "/clubs";
  return api.get(`${baseUrl}`, "");
}

function createClub(params: {
  fullName: string;
  mobile: string;
  numeroCompteClient?: string;
  club: string;
  status?: string;
  dateNaissance: string;
  lieuNaissance: string;
  nationalite: string;
  adresse: string;
  telephone?: string;
  fax?: string;
  email: string;
  idCard: string;
  sexe: string;
  degre: string;
  objectif: string;
  referenceBancaire: string;
  premierContact?: string;
  photo?: any;
  userId: string;
  ficheAdhesion?: string;
  ouvertureCompteClub?: string;
  cotisation: any;
}) {
  const baseUrl = "/clubs";
  const response = api.create(baseUrl, params);
  return response;
}

function updateClub(clubId: string, clubData: any) {
  const baseUrl = `/clubs/${clubId}`;
  const uploadUrl = `/upload`;

  //Mise à jour des fichiers du club
  if (clubData instanceof File) {
    //Mise à jour de la photo
    const updatedData: any = [];
    updatedData["_id"] = clubId;
    updatedData["photo"] = clubData;
    return api.createWithFile(uploadUrl, updatedData);
  } else if (clubData && clubData.files && clubData.files[0] instanceof File) {
    clubData["_id"] = clubId;
    return api.createWithFile(uploadUrl, clubData);
  } else {
    return api.update(`${baseUrl}`, clubData);
  }
}

function deleteClub(_id: any) {
  const baseUrl = `/clubs/${_id}`;
  return api.delete(`${baseUrl}`);
}

//COTISATION
function getCotisations() {
  const baseUrl = "/cotisations";
  return api.get(`${baseUrl}`, "");
}

function createCotisation(params: {
  titre: string;
  typeClub?: string;
  periode?: string;
  rendement?: string;
  moisEntre?: string;
  moisSortie?: string;
  moisCotisationRestant?: string;
  retour?: string;
  valeur?: string;
}) {
  const baseUrl = "/clubs/cotisation";
  const response = api.create(baseUrl, params);
  return response;
}

function updateCotisation(cotisationId: string, cotisationData: any) {
  const baseUrl = `/clubs/cotisation/${cotisationId}`;
  return api.update(`${baseUrl}`, cotisationData);
}

//MOUVEMENT
function createClubMouvement(params: {
  libelle: string;
  debit: number;
  credit: number;
  compteId: string;
  typeMouvement: string;
  userId: string;
}) {
  const baseUrl = "/clubs/mouvement";
  const response = api.create(baseUrl, params);
  return response;
}

export { createClub, updateClub, deleteClub, allClub, getClub, createCotisation, updateCotisation, createClubMouvement, getCotisations };
