import { UserActionTypes } from "./constants";
import { User } from "models/User";

type State = {
  users: User[];
  user: User | {};
  loading: boolean;
  error: string | null;
  success: boolean;
  deleted: boolean;
  added: boolean;
};

const initialState: State = {
  users: [],
  user: {},
  loading: false,
  error: null,
  success: false,
  deleted: false,
  added: false,
};

const Users = (state = initialState, action: any) => {
  switch (action.type) {
    case UserActionTypes.ALL_USER:
      return {
        ...state,
        loading: true,
      };
    case UserActionTypes.GET_USER:
      return {
        ...state,
        loading: true,
      };
    case UserActionTypes.CREATE_USER:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UserActionTypes.DELETE_USER:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UserActionTypes.API_RESPONSE_SUCCESS:
      if (action.payload.actionType === UserActionTypes.CREATE_USER) {
        return {
          ...state,
          loading: false,
          added: true,
        };
      } else if (action.payload.actionType === UserActionTypes.ALL_USER) {
        return {
          ...state,
          users: action.payload.data,
          loading: false,
          success: false,
        };
      } else if (action.payload.actionType === UserActionTypes.GET_USER) {
        return {
          ...state,
          user: action.payload.data,
          loading: false,
        };
      } else if (action.payload.actionType === UserActionTypes.DELETE_USER) {
        const newUsers = state.users.filter(
          (user) => user._id !== action.payload.data._id,
        );
        return {
          ...state,
          users: newUsers,
          deleted: true,
          loading: false,
        };
      }
      return state;
    case UserActionTypes.API_RESPONSE_ERROR:
      if (action.payload.actionType === UserActionTypes.CREATE_USER) {
        return {
          ...state,
          loading: false,
          error: action.payload.error,
          added: false,
        };
      } else if (action.payload.actionType === UserActionTypes.GET_USER) {
        return {
          ...state,
          loading: false,
          error: action.payload.error,
        };
      }
      return state;
    case UserActionTypes.RESET:
      return {
        ...state,
        loading: false,
        error: null,
        success: false,
        deleted: false,
        added: false,
      };
    default:
      return state;
  }
};

export default Users;
