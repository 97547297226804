import { UserActionTypes } from "./constants";

export type UserActionType = {
  type:
    | UserActionTypes.API_RESPONSE_SUCCESS
    | UserActionTypes.API_RESPONSE_ERROR
    | UserActionTypes.ALL_USER
    | UserActionTypes.GET_USER
    | UserActionTypes.CREATE_USER
    | UserActionTypes.UPDATE_USER
    | UserActionTypes.DELETE_USER
    | UserActionTypes.RESET;
  payload: {} | string;
};

export const userApiResponseSuccess = (
  actionType: string,
  data: any
): UserActionType => ({
  type: UserActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const userApiResponseError = (
  actionType: string,
  error: string
): UserActionType => ({
  type: UserActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const allUser = (): UserActionType => ({
  type: UserActionTypes.ALL_USER,
  payload: {},
});

export const createUser = (userData: any): UserActionType => ({
  type: UserActionTypes.CREATE_USER,
  payload: userData,
});

export const getUser = (_id: any): UserActionType => ({
  type: UserActionTypes.GET_USER,
  payload: { _id },
});

export const updateUser = (
  userId: string,
  updatedData: any
): UserActionType => ({
  type: UserActionTypes.UPDATE_USER,
  payload: { userId, updatedData },
});

export const deleteUser = (_id: any): UserActionType => ({
  type: UserActionTypes.DELETE_USER,
  payload: { _id },
});

export const resetUser = (): UserActionType => ({
  type: UserActionTypes.RESET,
  payload: {},
});
