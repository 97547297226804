import { ClubActionTypes } from "./constants";
import { Club, Cotisations } from "models/Club";

type State = {
  clubs: Club[];
  club: Club | {};
  cotisation: Club | {};
  cotisations: Cotisations | {};
  loading: boolean;
  error: string | null;
  success: boolean;
  deleted: boolean;
  added: boolean;
};

const initialState: State = {
  clubs: [],
  club: {},
  cotisation: {},
  cotisations: {},
  loading: false,
  error: null,
  success: false,
  deleted: false,
  added: false,
};

const Clubs = (state = initialState, action: any) => {
  switch (action.type) {
    case ClubActionTypes.ALL_CLUB:
      return {
        ...state,
        loading: true,
      };
    case ClubActionTypes.GET_CLUB:
      return {
        ...state,
        loading: true,
      };
    case ClubActionTypes.UPDATE_CLUB:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ClubActionTypes.CREATE_CLUB:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ClubActionTypes.DELETE_CLUB:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ClubActionTypes.GET_COTISATIONS:
      return {
        ...state,
        loading: true,
      };
    case ClubActionTypes.UPDATE_COTISATION:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ClubActionTypes.CREATE_COTISATION:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ClubActionTypes.CREATE_MOUVEMENT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ClubActionTypes.API_RESPONSE_SUCCESS:
      if (action.payload.actionType === ClubActionTypes.CREATE_CLUB) {
        return {
          ...state,
          loading: false,
          added: true,
        };
      } else if (action.payload.actionType === ClubActionTypes.ALL_CLUB) {
        return {
          ...state,
          clubs: action.payload.data,
          loading: false,
          success: false,
        };
      } else if (action.payload.actionType === ClubActionTypes.GET_CLUB) {
        return {
          ...state,
          club: action.payload.data,
          loading: false,
        };
      } else if (action.payload.actionType === ClubActionTypes.UPDATE_CLUB) {
        return {
          ...state,
          club: action.payload.data,
          loading: false,
          success: true,
        };
      } else if (action.payload.actionType === ClubActionTypes.DELETE_CLUB) {
        const newClubs = state.clubs.filter(
          (club) => club._id !== action.payload.data._id
        );
        return {
          ...state,
          clubs: newClubs,
          loading: false,
          deleted: true,
        };
      } else if (action.payload.actionType === ClubActionTypes.GET_COTISATIONS) {
        return {
          ...state,
          cotisations: action.payload.data,
          loading: false,
          success: false,
        };
      } else if (action.payload.actionType === ClubActionTypes.CREATE_COTISATION) {
        return {
          ...state,
          loading: false,
          added: true,
        };
      } else if (action.payload.actionType === ClubActionTypes.CREATE_MOUVEMENT) {
        return {
          ...state,
          loading: false,
          added: true,
        };
      } else if (action.payload.actionType === ClubActionTypes.UPDATE_COTISATION) {
        return {
          ...state,
          cotisation: action.payload.data,
          loading: false,
          success: true,
        };
      }
      return state;
    case ClubActionTypes.API_RESPONSE_ERROR:
      if (action.payload.actionType === ClubActionTypes.CREATE_CLUB) {
        return {
          ...state,
          loading: false,
          error: action.payload.error,
          added: false,
        };
      } else if (action.payload.actionType === ClubActionTypes.GET_CLUB) {
        return {
          ...state,
          loading: false,
          error: action.payload.error,
        };
      } else if (action.payload.actionType === ClubActionTypes.UPDATE_CLUB) {
        return {
          ...state,
          loading: false,
          error: action.payload.error,
        };
      } else if (action.payload.actionType === ClubActionTypes.GET_COTISATIONS) {
        return {
          ...state,
          loading: false,
          error: action.payload.error,
        };
      } else if (action.payload.actionType === ClubActionTypes.CREATE_COTISATION) {
        return {
          ...state,
          loading: false,
          error: action.payload.error,
        };
      } else if (action.payload.actionType === ClubActionTypes.UPDATE_COTISATION) {
        return {
          ...state,
          loading: false,
          error: action.payload.error,
        };
      } else if (action.payload.actionType === ClubActionTypes.CREATE_MOUVEMENT) {
        return {
          ...state,
          loading: false,
          error: action.payload.error,
        };
      }
      return state;
    case ClubActionTypes.RESET_CLUB:
      return {
        ...state,
        loading: false,
        error: null,
        success: false,
        deleted: false,
        added: false,
      };
    default:
      return state;
  }
};

export default Clubs;
