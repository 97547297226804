export enum CDealActionTypes {
  API_RESPONSE_SUCCESS = "@@cdeal/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR = "@@cdeal/API_RESPONSE_ERROR",

  //CDEAL
  ALL_CDEAL = "@@cdeal/ALL_CDEAL",
  GET_CDEAL = "@@cdeal/GET_CDEAL",
  CREATE_CDEAL = "@@cdeal/CREATE_CDEAL",
  GET_OPERATIONS = "@@cdeal/GET_OPERATIONS",
  CREATE_OPERATION = "@@cdeal/CREATE_OPERATION",
  UPDATE_OPERATION = "@@cdeal/UPDATE_OPERATION",
  UPDATE_CDEAL = "@@cdeal/UPDATE_CDEAL",
  DELETE_CDEAL = "@@cdeal/DELETE_CDEAL",
  RESET_CDEAL = "@@cdeal/RESET_CDEAL",
  CREATE_CMOUVEMENT = "@@cdeal/CREATE_CMOUVEMENT",
}
