import { combineReducers } from "redux";
import Auth from "./auth/reducers";
import Deals from "./deal/reducers";
import cDeals from "./cdeal/reducers";
import Users from "./user/reducers";
import Clubs from "./club/reducers";
import Titres from "./titre/reducers";
import Layout from "./layout/reducers";

export default combineReducers({
  Auth,
  Deals,
  Users,
  Clubs,
  Titres,
  cDeals,
  Layout,
});
