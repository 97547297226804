import { APICore } from "./apiCore";

const api = new APICore();

function getcDeal(dealId: any) {
  const baseUrl = `/cdeals/` + dealId;
  return api.get(`${baseUrl}`, "");
}

function allcDeal() {
  const baseUrl = "/cdeals";
  return api.get(`${baseUrl}`, "");
}

function createcDeal(params: {
  userId?: string;
  typeCompte: string;
  dealId?: string;

  //personne physique
  fullName?: string;
  dateNaissance?: string;
  lieuNaissance?: string;
  nationalite?: string;
  adresse?: string;

  sexe?: string;
  idCard?: string;

  //personne morale
  denomination?: string;
  abbreviation?: string;
  registryCommerce?: string;
  taxpayerAccount?: string;
  headquarters?: string;
  regime?: string;

  representant?: string;
  represenantIdCard?: string;

  //contact
  telephone?: string;
  fax?: string;
  mobile?: string;
  email: string;

  montant?: number;
  interet?: number;
  periode?: number;
  numeroCompteClient?: string;
  status?: string;
  referenceBancaire?: string;
  investissement?: any;
  photo?: any;
}) {
  const baseUrl = "/cdeals";
  const response = api.createWithFile(baseUrl, params);
  return response;
}

function updatecDeal(dealId: string, dealData: any) {
  const baseUrl = `/cdeals/${dealId}`;
  //Mise à jour des fichiers du deal
  if ((dealData.mandatGestion && dealData.mandatGestion.name) || (dealData.ouvertureComptecDeal && dealData.ouvertureComptecDeal.name)) {
    const baseUrl = `/upload`;
    dealData['_id'] = dealId
    return api.createWithFile(baseUrl, dealData);
  } else {
    return api.update(`${baseUrl}`, dealData);
  }
}

function deletecDeal(_id: any) {
  const baseUrl = `/cdeals/${_id}`;
  return api.delete(`${baseUrl}`);
}

//MOUVEMENT
function createcMouvement(params: {
  libelle: string;
  debit: number;
  credit: number;
  compteId: string;
  typeMouvement: string;
  userId: string;
}) {
  const baseUrl = "/cdeals/mouvement";
  const response = api.create(baseUrl, params);
  return response;
}

export { createcDeal, updatecDeal, deletecDeal, allcDeal, getcDeal, createcMouvement };
