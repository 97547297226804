import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import { DefaultLayout, VerticalLayout, HorizontalLayout, DetachedLayout, FullLayout } from 'layouts';
import PrivateRoute from './PrivateRoute';
import Root from './Root';
import { LayoutTypes } from 'appConstants';
import { useRedux } from 'hooks';

// lazy load all the views

// auth
const Login = React.lazy(() => import('pages/account/Login'));
const Logout = React.lazy(() => import('pages/account/Logout'));
const Register = React.lazy(() => import('pages/account/Register'));
const Confirm = React.lazy(() => import('pages/account/Confirm'));
const ConfirmedAccount = React.lazy(() => import('pages/account/ConfirmedAccount'));
const ForgetPassword = React.lazy(() => import('pages/account/ForgetPassword'));
const NewPassword = React.lazy(() => import('pages/account/NewPassword'));
const LockScreen = React.lazy(() => import('pages/account/LockScreen'));

// deals
const DealList = React.lazy(() => import('pages/deals/List'));
const DealDetail = React.lazy(() => import('pages/deals/Detail'));
const CreateDeal = React.lazy(() => import('pages/deals/CreateDeal'));

// utilisateurs
const ListeUtilisateurs = React.lazy(() => import('pages/utilisateurs/Utilisateurs'));
const DetailUtilisateur = React.lazy(() => import('pages/utilisateurs/Utilisateurs'));
const AjouterUtilisateur = React.lazy(() => import('pages/utilisateurs/AddUtilisateur'));

// clients
const ListeClients = React.lazy(() => import('pages/clients/Products'));
const DetailClients = React.lazy(() => import('pages/clients/Checkout'));

// comptes
const CompteClubs = React.lazy(() => import('pages/comptes/Club/Clubs'));
const CompteClubsNouveau = React.lazy(() => import('pages/comptes/Club/Add'));
const ClubView = React.lazy(() => import('pages/comptes/Club/View'));
//const CompteClubsNouveau = React.lazy(() => import('pages/comptes/Checkout'));
const PortefeuilleClub = React.lazy(() => import('pages/comptes/Club/Portefeuille/Clubs'));

//titres
const CompteTitres = React.lazy(() => import('pages/comptes/Titre/Titres'));
const AddTitre = React.lazy(() => import('pages/comptes/Titre/Add'));
const TitreView = React.lazy(() => import('pages/comptes/Titre/View'));
const PortefeuilleTitre = React.lazy(() => import('pages/comptes/Titre/Portefeuille/Titres'));

//deals
const CompteDeals = React.lazy(() => import('pages/comptes/Deal/Deal'));
const AddCompteDeals = React.lazy(() => import('pages/comptes/Deal/Add'));
const CompteDealView = React.lazy(() => import('pages/comptes/Deal/View'));
const PortefeuilleCompteDeal = React.lazy(() => import('pages/comptes/Deal/Portefeuille/Deals'));

// opérations
const OperationAchats = React.lazy(() => import('pages/operations/Operations'));

// mouvements
const Mouvements = React.lazy(() => import('pages/mouvements/Mouvements'));

// commandes
const Cotisations = React.lazy(() => import('pages/cotisations/Cotisations'));

const ErrorPageNotFound = React.lazy(() => import('pages/error/PageNotFound'));
const ServerError = React.lazy(() => import('pages/error/ServerError'));

const Maintenance = React.lazy(() => import('pages/other/Maintenance'));

const Landing = React.lazy(() => import('pages/landing/'));

const loading = () => <div className=""></div>;

type LoadComponentProps = {
  component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
  <Suspense fallback={loading()}>
    <Component />
  </Suspense>
);

const AllRoutes = () => {
  const { appSelector } = useRedux();

  const { layout } = appSelector((state) => ({
    layout: state.Layout,
  }));

  const getLayout = () => {
    let layoutCls: React.ComponentType = VerticalLayout;

    switch (layout.layoutType) {
      case LayoutTypes.LAYOUT_HORIZONTAL:
        layoutCls = HorizontalLayout;
        break;
      case LayoutTypes.LAYOUT_DETACHED:
        layoutCls = DetachedLayout;
        break;
      case LayoutTypes.LAYOUT_FULL:
        layoutCls = FullLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };
  let Layout = getLayout();

  /**
   * Defines the public routes and their child routes.
   * Includes routes for account management, errors, maintenance, and landing page.
   */
  const publicRoutes = [
    { path: "/", element: <Root /> },
    {
      // public routes
      path: "/",
      element: <DefaultLayout />,
      children: [
        {
          path: "account",
          children: [
            { path: "login", element: <LoadComponent component={Login} /> },
            {
              path: "register",
              element: <LoadComponent component={Register} />,
            },
            { path: "confirm", element: <LoadComponent component={Confirm} /> },
            {
              path: "confirm-account",
              element: <LoadComponent component={ConfirmedAccount} />,
            },
            {
              path: "forget-password",
              element: <LoadComponent component={ForgetPassword} />,
            },
            {
              path: "new-password",
              element: <LoadComponent component={NewPassword} />,
            },
            {
              path: "lock-screen",
              element: <LoadComponent component={LockScreen} />,
            },
            { path: "logout", element: <LoadComponent component={Logout} /> },
          ],
        },
        {
          path: "*",
          element: <LoadComponent component={ErrorPageNotFound} />,
        },
        {
          path: "error-500",
          element: <LoadComponent component={ServerError} />,
        },
        {
          path: "maintenance",
          element: <LoadComponent component={Maintenance} />,
        },
        {
          path: "landing",
          element: <LoadComponent component={Landing} />,
        },

      ],
    },
    {
      // auth protected routes
      path: '/',
      element: <PrivateRoute component={Layout} />,
      children: [
        {
          path: 'dashboard',
          children: [
            {
              path: 'deals',
              element: <LoadComponent component={DealList} />,
            },
            {
              path: 'deals/ajouter',
              element: <LoadComponent component={CreateDeal} />,
            },
            {
              path: 'deals/:id',
              element: <LoadComponent component={DealDetail} />,
            },
            {
              path: 'utilisateurs',
              element: <LoadComponent component={ListeUtilisateurs} />,
            },
            {
              path: 'utilisateurs/:id',
              element: <LoadComponent component={DetailUtilisateur} />,
            },
            {
              path: 'utilisateurs/ajouter',
              element: <LoadComponent component={AjouterUtilisateur} />,
            },
            {
              path: 'clients',
              element: <LoadComponent component={ListeClients} />,
            },
            {
              path: 'clients/:id',
              element: <LoadComponent component={DetailClients} />,
            },
            {
              path: 'comptes/titres',
              element: <LoadComponent component={CompteTitres} />,
            },
            {
              path: 'comptes/titres/:id',
              element: <LoadComponent component={CompteTitres} />,
            },
            {
              path: 'comptes/clubs',
              element: <LoadComponent component={CompteClubs} />,
            },
            {
              path: 'comptes/clubs/portefeuille/:id',
              element: <LoadComponent component={PortefeuilleClub} />,
            },
            {
              path: 'comptes/titres/portefeuille/:id',
              element: <LoadComponent component={PortefeuilleTitre} />,
            },
            {
              path: 'comptes/deals/portefeuille/:id',
              element: <LoadComponent component={PortefeuilleCompteDeal} />,
            },
            {
              path: 'comptes/clubs/nouveau',
              element: <LoadComponent component={CompteClubsNouveau} />,
            },
            {
              path: 'comptes/titres/nouveau',
              element: <LoadComponent component={AddTitre} />,
            },
            {
              path: 'comptes/clubs/club/:id',
              element: <LoadComponent component={ClubView} />,
            },
            {
              path: 'comptes/titres/titre/:id',
              element: <LoadComponent component={TitreView} />,
            },
            {
              path: 'comptes/deals/',
              element: <LoadComponent component={CompteDeals} />,
            },
            {
              path: 'comptes/deals/nouveau',
              element: <LoadComponent component={AddCompteDeals} />,
            },
            {
              path: 'comptes/deals/deal/:id',
              element: <LoadComponent component={CompteDealView} />,
            },
            {
              path: 'operations',
              element: <LoadComponent component={OperationAchats} />,
            },
            {
              path: 'cotisations',
              element: <LoadComponent component={Cotisations} />,
            },
            {
              path: 'approbations',
              element: <LoadComponent component={Cotisations} />,
            },
            {
              path: 'mouvements',
              element: <LoadComponent component={Mouvements} />,
            },
            {
              path: 'historiques',
              element: <LoadComponent component={Cotisations} />,
            },

          ],
        },
      ],
    },
  ];

  return useRoutes(publicRoutes);
};

export { AllRoutes };
