import { useRedux } from "hooks";
import { useEffect, useState } from 'react';

export type MenuItemType = {
  key: string;
  label: string;
  isTitle?: boolean;
  icon?: string;
  url?: string;
  badge?: {
    variant: string;
    text: string;
  };
  parentKey?: string;
  target?: string;
  children?: MenuItemType[];
};


export const useMenuItems = () => {
  const { appSelector } = useRedux();
  const { role } = appSelector((state) => ({
    role: state.Auth.user.role,
  }));

  const [menuItems, setMenuItems] = useState<MenuItemType[]>([]);

  useEffect(() => {
    console.log(role);
    if (role === 'Administrateur') {
      setMenuItems([
        { key: "navigation", label: "MENU", isTitle: true },
        {
          key: "deals",
          label: "Deals",
          isTitle: false,
          icon: "uil-clipboard-alt",
          children: [
            {
              key: "ds-deals",
              label: "Liste des deals",
              url: "/dashboard/deals",
              parentKey: "deals",
            },
            {
              key: "ds-deal",
              label: "Ajouter un deal",
              url: "/dashboard/deals/ajouter",
              parentKey: "deals",
            },
          ],
        },
        {
          key: "users",
          label: "Utilisateurs",
          isTitle: false,
          icon: "uil-user",
          children: [
            {
              key: "ds-utilisateurs",
              label: "Liste des utilisateurs",
              url: "/dashboard/utilisateurs",
              parentKey: "users",
            },
            {
              key: "ds-utilisateur",
              label: "Ajouter un utilisateur",
              url: "/dashboard/utilisateurs/ajouter",
              parentKey: "users",
            },
          ],
        },
        {
          key: "comptes",
          label: "Mes comptes",
          isTitle: false,
          icon: "uil-moneybag",
          children: [
            {
              key: "ds-titre",
              label: "Titres",
              url: "/dashboard/comptes/titres",
              parentKey: "comptes",
            },
            {
              key: "ds-clubs",
              label: "Clubs",
              url: "/dashboard/comptes/clubs",
              parentKey: "comptes",
            },
            {
              key: "ds-cdeal",
              label: "Deals",
              url: "/dashboard/comptes/deals",
              parentKey: "comptes",
            },
          ],
        },
        {
          key: "operations",
          label: "Opérations",
          isTitle: false,
          url: "/dashboard/operations",
          icon: "uil-invoice",
        },/*
    {
      key: "cotisations",
      label: "Cotisations",
      isTitle: false,
      url: "/dashboard/cotisations",
      icon: "uil-invoice",
    },*/
        {
          key: "mouvements",
          label: "Mouvements",
          isTitle: false,
          url: "/dashboard/mouvements",
          icon: "uil-invoice",
        },
      ]);
    } else if (role === 'Gerant') {
      setMenuItems([
        { key: "navigation", label: "MENU", isTitle: true },
        {
          key: "deals",
          label: "Deals",
          isTitle: false,
          icon: "uil-clipboard-alt",
          children: [
            {
              key: "ds-deals",
              label: "Liste des deals",
              url: "/dashboard/deals",
              parentKey: "deals",
            },
            {
              key: "ds-deal",
              label: "Ajouter un deal",
              url: "/dashboard/deals/ajouter",
              parentKey: "deals",
            },
          ],
        },
        {
          key: "users",
          label: "Utilisateurs",
          isTitle: false,
          icon: "uil-user",
          children: [
            {
              key: "ds-utilisateurs",
              label: "Liste des utilisateurs",
              url: "/dashboard/utilisateurs",
              parentKey: "users",
            },
            {
              key: "ds-utilisateur",
              label: "Ajouter un utilisateur",
              url: "/dashboard/utilisateurs/ajouter",
              parentKey: "users",
            },
          ],
        },
        {
          key: "comptes",
          label: "Mes comptes",
          isTitle: false,
          icon: "uil-moneybag",
          children: [
            {
              key: "ds-titre",
              label: "Titres",
              url: "/dashboard/comptes/titres",
              parentKey: "comptes",
            },
            {
              key: "ds-clubs",
              label: "Clubs",
              url: "/dashboard/comptes/clubs",
              parentKey: "comptes",
            },
            {
              key: "ds-cdeal",
              label: "Deals",
              url: "/dashboard/comptes/deals",
              parentKey: "comptes",
            },
          ],
        },
        {
          key: "operations",
          label: "Opérations",
          isTitle: false,
          url: "/dashboard/operations",
          icon: "uil-invoice",
        },
        /*{
          key: "cotisations",
          label: "Cotisations",
          isTitle: false,
          url: "/dashboard/cotisations",
          icon: "uil-invoice",
        },*/
        {
          key: "mouvements",
          label: "Mouvements",
          isTitle: false,
          url: "/dashboard/mouvements",
          icon: "uil-invoice",
        },
      ])
    } else if (role === 'Client') {
      setMenuItems([
        { key: "navigation", label: "MENU", isTitle: true },
        {
          key: "ds-deals",
          label: "Liste des deals",
          isTitle: false,
          url: "/dashboard/deals",
          icon: "uil-clipboard-alt",
        },
        {
          key: "comptes",
          label: "Mes comptes",
          isTitle: false,
          icon: "uil-moneybag",
          children: [
            {
              key: "ds-titre",
              label: "Titres",
              url: "/dashboard/comptes/titres",
              parentKey: "comptes",
            },
            {
              key: "ds-clubs",
              label: "Clubs",
              url: "/dashboard/comptes/clubs",
              parentKey: "comptes",
            },
            {
              key: "ds-cdeal",
              label: "Deals",
              url: "/dashboard/comptes/deals",
              parentKey: "comptes",
            },
          ],
        },
        {
          key: "operations",
          label: "Opérations",
          isTitle: false,
          url: "/dashboard/operations",
          icon: "uil-invoice",
        },/*
    {
      key: "cotisations",
      label: "Cotisations",
      isTitle: false,
      url: "/dashboard/cotisations",
      icon: "uil-invoice",
    }*/
      ]);
    }
  }, [role]);

  return menuItems;
};
