import { TitreActionTypes } from "./constants";

export type TitreActionType = {
  type:
  | TitreActionTypes.API_RESPONSE_SUCCESS
  | TitreActionTypes.API_RESPONSE_ERROR
  | TitreActionTypes.ALL_TITRE
  | TitreActionTypes.GET_TITRE
  | TitreActionTypes.CREATE_TITRE
  | TitreActionTypes.UPDATE_TITRE
  | TitreActionTypes.GET_OPERATIONS
  | TitreActionTypes.CREATE_OPERATION
  | TitreActionTypes.UPDATE_OPERATION
  | TitreActionTypes.DELETE_TITRE
  | TitreActionTypes.RESET_TITRE
  | TitreActionTypes.CREATE_MOUVEMENT
  payload: {} | string;
};

export const titreApiResponseSuccess = (
  actionType: string,
  data: any
): TitreActionType => ({
  type: TitreActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const titreApiResponseError = (
  actionType: string,
  error: string
): TitreActionType => ({
  type: TitreActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

//TITRE
export const allTitre = (): TitreActionType => ({
  type: TitreActionTypes.ALL_TITRE,
  payload: {},
});

export const getTitre = (_id: any): TitreActionType => ({
  type: TitreActionTypes.GET_TITRE,
  payload: { _id },
});

export const createTitre = (titreData: any): TitreActionType => ({
  type: TitreActionTypes.CREATE_TITRE,
  payload: titreData,
});

export const updateTitre = (
  titreId: string,
  updatedData: any
): TitreActionType => ({
  type: TitreActionTypes.UPDATE_TITRE,
  payload: { titreId, updatedData },
});

export const deleteTitre = (_id: any): TitreActionType => ({
  type: TitreActionTypes.DELETE_TITRE,
  payload: { _id },
});

export const resetTitre = (): TitreActionType => ({
  type: TitreActionTypes.RESET_TITRE,
  payload: {},
});

//OPERATION
export const getOperations = (): TitreActionType => ({
  type: TitreActionTypes.GET_OPERATIONS,
  payload: {},
});

export const createOperation = (operationData: any): TitreActionType => ({
  type: TitreActionTypes.CREATE_OPERATION,
  payload: operationData,
});

export const updateOperation = (
  operationId: string,
  updatedData: any
): TitreActionType => ({
  type: TitreActionTypes.UPDATE_OPERATION,
  payload: { operationId, updatedData },
});

//MOUVEMENT
export const createMouvement = (mouvementData: any): TitreActionType => ({
  type: TitreActionTypes.CREATE_MOUVEMENT,
  payload: mouvementData,
});
