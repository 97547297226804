import { AuthActionTypes } from "./constants";
import { User } from "models/User";

export type AuthActionType = {
  type:
  | AuthActionTypes.API_RESPONSE_SUCCESS
  | AuthActionTypes.API_RESPONSE_ERROR
  | AuthActionTypes.FORGOT_PASSWORD
  | AuthActionTypes.RESET_PASSWORD
  | AuthActionTypes.FORGOT_PASSWORD_CHANGE
  | AuthActionTypes.LOGIN_USER
  | AuthActionTypes.LOGOUT_USER
  | AuthActionTypes.RESET
  | AuthActionTypes.SIGNUP_USER;
  payload: {} | string;
};

// common success
export const authApiResponseSuccess = (
  actionType: string,
  data: User | {}
): AuthActionType => ({
  type: AuthActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const authApiResponseError = (
  actionType: string,
  error: string
): AuthActionType => ({
  type: AuthActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const loginUser = (email: string, password: string): AuthActionType => ({
  type: AuthActionTypes.LOGIN_USER,
  payload: { email, password },
});

export const logoutUser = (): AuthActionType => ({
  type: AuthActionTypes.LOGOUT_USER,
  payload: {},
});

export const signupUser = (
  username: string,
  email: string,
  password: string,
  fullName: string,
  phone: string
): AuthActionType => ({
  type: AuthActionTypes.SIGNUP_USER,
  payload: { username, email, password, fullName, phone },
});

export const forgotPassword = (username: string): AuthActionType => ({
  type: AuthActionTypes.FORGOT_PASSWORD,
  payload: { username },
});

export const resetPassword = (email: string, token: string, password: string): AuthActionType => ({
  type: AuthActionTypes.RESET_PASSWORD,
  payload: { email, token, password },
});

export const resetAuth = (): AuthActionType => ({
  type: AuthActionTypes.RESET,
  payload: {},
});
