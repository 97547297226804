import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { SagaIterator } from "@redux-saga/core";

import {
  getcDeal as getCDealApi,
  allcDeal as allCDealApi,
  createcDeal as createCDealApi,
  updatecDeal as updateCDealApi,
  deletecDeal as deleteCDealApi,
  createcMouvement as createMouvementApi,
} from "helpers";

import { cdealApiResponseSuccess, cdealApiResponseError } from "./actions";
import { CDealActionTypes } from "./constants";

type CDealData = {
  payload: {
    _id?: string;
    userId?: string;
    typeCompte: string;
    dealId?: string;

    //personne physique
    fullName?: string;
    dateNaissance?: string;
    lieuNaissance?: string;
    nationalite?: string;
    adresse?: string;

    sexe?: string;
    idCard?: string;

    //personne morale
    denomination?: string;
    abbreviation?: string;
    registryCommerce?: string;
    taxpayerAccount?: string;
    headquarters?: string;
    regime?: string;

    representant?: string;
    represenantIdCard?: string;

    //contact
    telephone?: string;
    fax?: string;
    mobile?: string;
    email: string;

    montant?: number;
    interet?: number;
    periode?: number;
    numeroCompteClient?: string;
    referenceBancaire?: string;
    status?: string;
    investissement?: any;
    photo?: any;
  };
  type: string;
};


type MouvementData = {
  payload: {
    _id?: string;
    libelle: string;
    debit: number;
    credit: number;
    compteId: string;
    typeMouvement: string;
    userId: string;
  };
  type: string;
};

function* allCDeal(): SagaIterator {
  try {
    const response = yield call(allCDealApi);
    if (response) {
      yield put(
        cdealApiResponseSuccess(CDealActionTypes.ALL_CDEAL, response.data)
      );
    }
  } catch (error: any) {
    yield put(cdealApiResponseError(CDealActionTypes.ALL_CDEAL, error)); // Assurez-vous d'appeler l'action appropriée pour gérer les erreurs de la création du deal.
  }
}

function* createCDeal({
  payload: {
    userId,
    dealId,
    typeCompte,
    fullName,
    dateNaissance,
    lieuNaissance,
    nationalite,
    adresse,
    sexe,
    idCard,
    denomination,
    abbreviation,
    registryCommerce,
    taxpayerAccount,
    headquarters,
    regime,
    telephone,
    fax,
    mobile,
    email,
    montant,
    interet,
    periode,
    numeroCompteClient,
    status,
    referenceBancaire
  },
  type,
}: CDealData): SagaIterator {
  try {
    const response = yield call(createCDealApi, {
      userId,
      dealId,
      typeCompte,
      fullName,
      dateNaissance,
      lieuNaissance,
      nationalite,
      adresse,
      sexe,
      idCard,
      denomination,
      abbreviation,
      registryCommerce,
      taxpayerAccount,
      headquarters,
      regime,
      telephone,
      fax,
      mobile,
      email,
      montant,
      interet,
      periode,
      numeroCompteClient,
      status,
      referenceBancaire
    });
    if (response) {
      yield put(
        cdealApiResponseSuccess(CDealActionTypes.CREATE_CDEAL, response.data)
      );
    }
  } catch (error: any) {
    yield put(cdealApiResponseError(CDealActionTypes.CREATE_CDEAL, error));
  }
}

function* deleteCDeal({ payload: { _id }, type }: CDealData): SagaIterator {
  try {
    const response = yield call(deleteCDealApi, _id);

    if (response) {
      yield put(
        cdealApiResponseSuccess(CDealActionTypes.DELETE_CDEAL, response.data)
      );
    }
  } catch (error: any) {
    yield put(cdealApiResponseError(CDealActionTypes.DELETE_CDEAL, error));
  }
}

function* getCDeal({ payload: { _id }, type }: CDealData): SagaIterator {
  try {
    const response = yield call(getCDealApi, _id);

    if (response) {
      yield put(
        cdealApiResponseSuccess(CDealActionTypes.GET_CDEAL, response.data)
      );
    }
  } catch (error: any) {
    yield put(cdealApiResponseError(CDealActionTypes.GET_CDEAL, error));
  }
}

function* updateCDeal({ payload: { dealId, updatedData } }: any): SagaIterator {
  try {
    const response = yield call(updateCDealApi, dealId, updatedData);
    yield put(
      cdealApiResponseSuccess(CDealActionTypes.UPDATE_CDEAL, response.data)
    );
  } catch (error: any) {
    yield put(cdealApiResponseError(CDealActionTypes.UPDATE_CDEAL, error));
  }
}

function* createMouvement({
  payload: {
    libelle,
    debit,
    credit,
    compteId,
    typeMouvement,
    userId
  },
  type,
}: MouvementData): SagaIterator {
  try {
    const response = yield call(createMouvementApi, {
      libelle,
      debit,
      credit,
      compteId,
      typeMouvement,
      userId
    });
    if (response) {
      yield put(
        cdealApiResponseSuccess(CDealActionTypes.CREATE_CMOUVEMENT, response.data)
      );
    }
  } catch (error: any) {
    yield put(cdealApiResponseError(CDealActionTypes.CREATE_CMOUVEMENT, error));
  }
}

export function* watchCreateCDeal() {
  yield takeEvery(CDealActionTypes.CREATE_CDEAL, createCDeal);
}
export function* watchAllCDeal() {
  yield takeEvery(CDealActionTypes.ALL_CDEAL, allCDeal);
}
export function* watchGetCDeal() {
  yield takeEvery(CDealActionTypes.GET_CDEAL, getCDeal);
}
export function* watchUpdateCDeal() {
  yield takeEvery(CDealActionTypes.UPDATE_CDEAL, updateCDeal);
}
export function* watchCreateMouvement() {
  yield takeEvery(CDealActionTypes.CREATE_CMOUVEMENT, createMouvement);
}
export function* watchDeleteCDeal() {
  yield takeEvery(CDealActionTypes.DELETE_CDEAL, deleteCDeal);
}

function* cdealSaga() {
  yield all([fork(watchAllCDeal)]);
  yield all([fork(watchGetCDeal)]);
  yield all([fork(watchUpdateCDeal)]);
  yield all([fork(watchCreateCDeal)]);
  yield all([fork(watchDeleteCDeal)]);
  yield all([fork(watchCreateMouvement)]);
}

export default cdealSaga;
