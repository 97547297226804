import { ClubActionTypes } from "./constants";

export type ClubActionType = {
  type:
  | ClubActionTypes.API_RESPONSE_SUCCESS
  | ClubActionTypes.API_RESPONSE_ERROR
  | ClubActionTypes.ALL_CLUB
  | ClubActionTypes.GET_CLUB
  | ClubActionTypes.CREATE_CLUB
  | ClubActionTypes.UPDATE_CLUB
  | ClubActionTypes.DELETE_CLUB
  | ClubActionTypes.RESET_CLUB
  | ClubActionTypes.CREATE_MOUVEMENT
  | ClubActionTypes.CREATE_COTISATION
  | ClubActionTypes.UPDATE_COTISATION
  | ClubActionTypes.GET_COTISATIONS
  payload: {} | string;
};

export const clubApiResponseSuccess = (
  actionType: string,
  data: any
): ClubActionType => ({
  type: ClubActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

export const clubApiResponseError = (
  actionType: string,
  error: string
): ClubActionType => ({
  type: ClubActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

//CLUB
export const allClub = (): ClubActionType => ({
  type: ClubActionTypes.ALL_CLUB,
  payload: {},
});

export const getClub = (_id: any): ClubActionType => ({
  type: ClubActionTypes.GET_CLUB,
  payload: { _id },
});

export const createClub = (clubData: any): ClubActionType => ({
  type: ClubActionTypes.CREATE_CLUB,
  payload: clubData,
});

export const updateClub = (
  clubId: string,
  updatedData: any
): ClubActionType => ({
  type: ClubActionTypes.UPDATE_CLUB,
  payload: { clubId, updatedData },
});

export const deleteClub = (_id: any): ClubActionType => ({
  type: ClubActionTypes.DELETE_CLUB,
  payload: { _id },
});

export const resetClub = (): ClubActionType => ({
  type: ClubActionTypes.RESET_CLUB,
  payload: {},
});

//COTISATION
export const createCotisation = (cotisationData: any): ClubActionType => ({
  type: ClubActionTypes.CREATE_COTISATION,
  payload: cotisationData,
});

export const updateCotisation = (
  operationId: string,
  updatedData: any
): ClubActionType => ({
  type: ClubActionTypes.UPDATE_COTISATION,
  payload: { operationId, updatedData },
});

export const getCotisations = (): ClubActionType => ({
  type: ClubActionTypes.GET_COTISATIONS,
  payload: {},
});

//MOUVEMENT
export const createClubMouvement = (mouvementData: any): ClubActionType => ({
  type: ClubActionTypes.CREATE_MOUVEMENT,
  payload: mouvementData,
});
